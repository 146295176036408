<template>
  <v-app
    :class="{
      'in-iframe': inIframe,
      'member-layout': !inIframe,
      'v-app--with-paywall': !barHidden,
    }"
  >
    <div class="member-layout__background" />

    <AIChat v-if="!inIframe" />
    <ThePaywall v-if="!inIframe" />
    <Sidebar v-if="showSidebar" />
    <Topbar v-if="showTopbar"/>

    <v-main :class="{ 'has-topbar': showTopbar }">
      <TheNavigationLandscape :navigation-group-path="navigationGroupPath">
        <NuxtPage />
      </TheNavigationLandscape>
    </v-main>

    <template v-if="!inIframe">
      <NewVersionAlert />
      <Snackbar />
      <MemberDock :only-helper="isPostInstallationSetupView" />
    </template>

    <TheDialog />
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from '~/assets/javascript/modules/vuex';
import { userNotificationChannelMixin } from '~/mixins/userNotificationChannelMixin';
import AIChat from '~/components/ai-chat';
import MemberDock from '~/components/docks/member';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';
import NewVersionAlert from '~/components/NewVersionAlert';
import ThePaywall from '~/components/the-paywall';
import TheNavigationLandscape from '~/components/views/the-navigation-landscape';

export default defineComponent({
  name: 'Member',
  components: {
    AIChat,
    MemberDock,
    NewVersionAlert,
    Sidebar: defineAsyncComponent(() => import('~/components/sidebar')),
    Topbar: defineAsyncComponent(() => import('~/components/topbars/member-topbar')),
    Snackbar,
    TheDialog,
    TheNavigationLandscape,
    ThePaywall,
  },
  mixins: [userNotificationChannelMixin],
  setup() {
    useWorkspace();
    buildHead();
    return {
      ...mapState('view', ['view']),
      ...mapState('ai', { isAIChatOpen: 'isOpen' }),
      ...mapGetters('paywall', ['barHidden']),
      ...mapActions('paywall', ['loadBarState']),
      ...mapMutations('environment', ['setCurrentEnvironment']),
      ...mapMutations('studio', ['setOutsideStudio']),
      ...mapState('environment', ['inIframe']),
      ...mapGetters('workspace', ['currentCategoryNavigationType']),
      ...mapGetters('viewsMenu', ['generateViewNavigationPath']),
      ...mapGetters('view', ['isHiddenFromSidebar', 'isPostInstallationSetupView']),
    };
  },
  computed: {
    showSidebar() {
      if (this.inIframe || this.isHiddenFromSidebar || this.isAIChatOpen) return false;

      return this.currentCategoryNavigationType === 'Sidebar' || this.$vuetify.display.mdAndDown;
    },
    showTopbar() {
      if (this.inIframe || this.isHiddenFromSidebar) return false;
      if (this.$vuetify.display.mdAndDown) return false;
      return this.currentCategoryNavigationType === 'Topbar';
    },
    navigationGroupPath() {
      if (this.isHiddenFromSidebar || this.view.id !== this.$route.params.viewId) return [];

      return this.generateViewNavigationPath(this.view.id);
    },
  },
  beforeMount() {
    this.loadBarState();
    this.setOutsideStudio();
    this.setCurrentEnvironment('member');
    const utmsObject = _pickBy(this.$route.query, (_value, key) => _startsWith(key, 'utm_'));

    if (!_isEmpty(utmsObject)) {
      window.analytics.track('referredFrom', utmsObject);
    }
  },
});
</script>
<style lang="scss">
@supports not (height: 100dvh) {
  /**
  * All scrollable contents in the member area are confined to specific elements,
  * so if `dvh` unit is not supported, it's presumably safe to disable scrolling
  * on the body and html elements to avoid mobile browsers address bar on top of
  * the page and having a double overflow.
  *
  * PSA: in `__generic.scss` is where we set the `html` height to `100dvh`
  */
  body, html {
    overflow: hidden;
  }
}

.v-app--with-paywall .topbar,
.v-app--with-paywall .sidebar,
.v-app--with-paywall .v-main {
  margin-top: 48px; /* matches ThePaywallBar's height */
  transition: margin-top 0.3s ease-in-out;
}

// we are using a custom launcher for intercom, so we need to hide the default one
.intercom-messenger-frame {
  bottom: var(--z-s4) !important;
  right: var(--z-s4) !important;
}

.intercom-lightweight-app-launcher,
.intercom-launcher {
  display: none !important;
}

.intercom-app {
  &>div:nth-child(2) {
    display: none !important;
  }
}
</style>
